import {CSSProperties} from 'react'
import {COLORS_CONSTS} from '../../../styles/colors.consts'

export const footerWrapperContainerStyle: CSSProperties = {
  width: '90%',
  margin: 'auto',
  paddingBottom: '14px'
}

export const footerContainerStyle: CSSProperties = {
  background: COLORS_CONSTS.colorWhite,
  width: '100%',
  margin: '0 auto',
  padding: '16px 0px'
  // borderBottom: `1px solid ${COLORS_CONSTS.colorGrey30}`
}

export const ageRestrictionLogoStyle: CSSProperties = {
  color: COLORS_CONSTS.colorGrey50,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '125%',
  marginTop: '12px'
}

export const footerRightColumnStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column-reverse',
  width: '100%',
  justifyContent: 'space-between'
}

export const logosBlockStyle: CSSProperties = {
  width: '100%'
}

export const logosBlockRowStyle: CSSProperties = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column'
}

export const iconsBlockStyle: CSSProperties = {
  display: 'flex',
  columnGap: '34px',
  rowGap: '20px',
  marginTop: '30px'
}

export const legalInfoBlockTextStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '125%',
  color: COLORS_CONSTS.colorGrey100,
  textDecoration: 'none',
  maxWidth: '680px',
  marginTop: '30px'
}

export const legalInfoBlockTextItemStyle: CSSProperties = {
  marginBottom: '10px'
}

export const agreementsBlockStyle: CSSProperties = {
  width: '100%',
  margin: '12px 0px',
  flexDirection: 'column'
}

export const agreementsBlockLinkStyle: CSSProperties = {
  color: `${COLORS_CONSTS.colorBlack}`,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '125%',
  textDecoration: 'none',
  marginBottom: '8px'
}
