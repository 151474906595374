import React, {FC} from 'react'
import {AmpFooterIconProps} from '../ampFooterIconProps'

const AMPFooterMailingList: FC<AmpFooterIconProps> = ({
  isHeader,
  width,
  height
}) => {
  return !isHeader ? (
    <svg
      width={width ? width : '40'}
      height={height ? height : '40'}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='20' fill='#5B97FF' />
      <g clipPath='url(#clip0_7666_186440)'>
        <path
          d='M30 10H10C8.625 10 7.5125 11.125 7.5125 12.5L7.5 27.5C7.5 28.875 8.625 30 10 30H30C31.375 30 32.5 28.875 32.5 27.5V12.5C32.5 11.125 31.375 10 30 10ZM30 15L20 21.25L10 15V12.5L20 18.75L30 12.5V15Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_7666_186440'>
          <rect
            width='30'
            height='30'
            fill='white'
            transform='translate(5 5)'
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='20' height='20' rx='10' fill='#5B97FF' />
      <g clipPath='url(#clip0_7666_186547)'>
        <path
          d='M15 5H5C4.3125 5 3.75625 5.5625 3.75625 6.25L3.75 13.75C3.75 14.4375 4.3125 15 5 15H15C15.6875 15 16.25 14.4375 16.25 13.75V6.25C16.25 5.5625 15.6875 5 15 5ZM15 7.5L10 10.625L5 7.5V6.25L10 9.375L15 6.25V7.5Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_7666_186547'>
          <rect
            width='15'
            height='15'
            fill='white'
            transform='translate(2.5 2.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AMPFooterMailingList
