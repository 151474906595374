import React, {FC, useEffect, useState} from 'react'
import {AMP_FOOTER_CONSTS} from './ampFooter.consts'
import Logo from '../../../assets/icons/svg/logo.svg'
import useDeviceDetect from '../../../utils/useDeviceDetect'
import {
  footerWrapperContainerStyle,
  footerContainerStyle,
  logosBlockStyle,
  logosBlockRowStyle,
  iconsBlockStyle,
  ageRestrictionLogoStyle,
  legalInfoBlockTextStyle,
  // agreementsBlockStyle,
  // agreementsBlockLinkStyle,
  footerRightColumnStyle,
  legalInfoBlockTextItemStyle
} from './ampFooter.styles.consts'
import AmpShareBlock from '../../ui/blocks/amp/ampShareBlock/ampShareBlock'
import Link from 'next/link'

const AmpFooter: FC<any> = () => {
  const {isMobile} = useDeviceDetect()

  const [iconHeight, setIconHeight] = useState(24)
  const [iconWidth, setIconWidth] = useState(24)

  useEffect(() => {
    setIconHeight(isMobile ? 36 : 36)
    setIconWidth(isMobile ? 36 : 36)
  }, [isMobile])

  return (
    <div style={footerWrapperContainerStyle}>
      <div style={footerContainerStyle}>
        <div style={logosBlockStyle}>
          <div style={logosBlockRowStyle}>
            <div>
              <Logo />
              <div style={ageRestrictionLogoStyle}>
                {AMP_FOOTER_CONSTS.ageRestrectionBanner}
              </div>
            </div>
            <div style={footerRightColumnStyle}>
              <div>
                <div style={legalInfoBlockTextStyle}>
                  <div style={legalInfoBlockTextItemStyle}>
                    {AMP_FOOTER_CONSTS.siteNameInfo}
                  </div>
                  <div style={legalInfoBlockTextItemStyle} itemProp='name'>
                    <span>{AMP_FOOTER_CONSTS.domainName}</span>
                    <Link href='/'>ura-help.ru</Link>
                  </div>
                  <div style={legalInfoBlockTextItemStyle} itemProp='email'>
                    {AMP_FOOTER_CONSTS.emailMsg}
                    <Link href={`mailto:${AMP_FOOTER_CONSTS.email}`}>
                      {AMP_FOOTER_CONSTS.email}
                    </Link>
                  </div>
                  {/* <div>
                {AMP_FOOTER_CONSTS.mainRedactorHeader}{' '}
                {AMP_FOOTER_CONSTS.mainRedactorName}
              </div>
              <div itemProp='telephone'>
                {AMP_FOOTER_CONSTS.legalInfoBanner3Phone}
              </div>
              <div>
                {AMP_FOOTER_CONSTS.legalInfoBanner3Mail}{' '}
                {AMP_FOOTER_CONSTS.redactionMail}
              </div> */}
                </div>
              </div>
              <div>
                <div style={iconsBlockStyle}>
                  <AmpShareBlock
                    columnGap={'34px'}
                    rowGap={'20px'}
                    showShareMsg={false}
                    iconHeight={iconHeight}
                    iconWidth={iconWidth}
                  ></AmpShareBlock>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={agreementsBlockStyle}>
        <div>
          <a
            style={agreementsBlockLinkStyle}
            href='/user-agreements'
            target='_blank'
            rel='noreferrer'
          >
            {AMP_FOOTER_CONSTS.termsOfUseLink}
          </a>
        </div>
        <div>
          <a
            style={agreementsBlockLinkStyle}
            href='/confidential-policy'
            target='_blank'
            rel='noreferrer'
          >
            {AMP_FOOTER_CONSTS.privacyPolicyLink}
          </a>
        </div>
      </div> */}
    </div>
  )
}
export default AmpFooter
