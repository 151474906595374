export const AMP_FOOTER_CONSTS = {
  siteNameInfo:
    '«Ура, помощь!» - проект, который рассказывает простыми словами о сложных вещах: законах, налогах и деньгах. Мы говорим о рутине и объясняем, как жить и планировать в меняющемся мире.',
  domainName: 'Доменное имя: ',
  email: 'info@ura-help.ru',
  emailMsg: 'Электронная почта: ',
  ageRestrectionBanner: 'Для лиц старше 18 лет',
  termsOfUseLink: 'Пользовательское соглашение',
  privacyPolicyLink: 'Политика конфиденциальности'
}
