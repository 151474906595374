export const SHARE_BLOCK_CONSTS = {
  dzenLinkUrl: 'https://dzen.ru/urahelp',
  dzen: 'Дзен',
  dzenNewsLinkUrl: 'https://dzen.ru/news?favid=2648&issue_tld=ru',
  mailId: 'mail-link',
  mailLinkMsg: 'Поделиться статьей по почте',
  mailMsg: 'Почта',
  mailPreMessage: 'Хочу поделиться статьей с ura-help.ru: ',
  pushNotification: 'PUSH-уведомления',
  rutube: 'Rutube',
  rutubeId: 'rutube-link',
  rutubeLinkUrl: 'https://rutube.ru/channel/23642434/videos/',
  shareMsg: 'Поделиться',
  shareInMsg: 'Поделиться в ',
  subscription: 'Почтовая рассылка',
  telegram: 'Телеграм',
  telegramId: 'telegram-link',
  telegramLinkUrl: 'https://t.me/urahelp',
  vkId: 'vk-link',
  vkontakte: 'Вконтакте',
  vKLinkUrl: 'https://vk.com/urahelp',
  whatsup: 'whatsApp',
  whatsupId: 'whats-app-link',
  whatsupLinkUrl: 'https://vk.com/ura_ru',
  youtube: 'Youtube',
  youtubeId: 'youtube-id',
  youtubeLinkUrl: 'https://www.youtube.com/channel/UCrAqF8alWsWuE_SoCLJ6zCQ'
}
