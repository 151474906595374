import {inject, observer} from 'mobx-react'
import {FC} from 'react'
import {DeviceSettingsStore} from '../../../../../stores/deviceSettingsStore'
import AMPFooterDzen from '../../../icons/ampIcons/footerIcons/footerDzen/footerDzen'
// import FooterMailingList from '../../../Icons/AMPIcons/FooterIcons/FooterMailingList/FooterMailingList'
import AMPFooterTelegram from '../../../icons/ampIcons/footerIcons/footerTelegram/footerTelegram'
import AMPFooterVK from '../../../icons/ampIcons/footerIcons/footerVK/footerVK'
import {SHARE_BLOCK_CONSTS} from '../../shareBlock/shareBlock.consts'
import FooterMailingList from '../../../icons/ampIcons/footerIcons/footerMailingList/footerMailingList'

type ShareBlockProps = {
  showShareMsg: boolean
  iconHeight: number
  iconWidth: number
  deviceSettingsStore: DeviceSettingsStore
  columnGap: string
  rowGap: string
  url?: string
  title?: string
}

const AmpShareBlock: FC<any> = inject('deviceSettingsStore')(
  observer((props: ShareBlockProps) => {
    const showShareMsg = props.showShareMsg || false

    // const printPage = useCallback(() => {
    //   window.print()
    // }, [])

    const iconHeight = props.iconHeight || 24
    const iconWidth = props.iconWidth || 4
    const columnGap = props.columnGap || '34px'
    const rowGap = props.rowGap || '20px'
    const telegramLink =
      props.url || props.title
        ? `https://telegram.me/share/url?url=${props.url}`
        : SHARE_BLOCK_CONSTS.telegramLinkUrl
    const vkLink =
      props.url || props.title
        ? `https://vk.com/share.php?url=${props.url}&text=${props.title}`
        : SHARE_BLOCK_CONSTS.vKLinkUrl
    const mailLink =
      props.url || props.title
        ? `mailto:&body=${SHARE_BLOCK_CONSTS.mailPreMessage}${props.url}?subject=${props.title}`
        : `mailto:obzor@ura.ru`
    const telegramId =
      props.url || props.title
        ? `article-${SHARE_BLOCK_CONSTS.telegramId}`
        : `footer-${SHARE_BLOCK_CONSTS.telegramId}`
    const vkId =
      props.url || props.title
        ? `article-${SHARE_BLOCK_CONSTS.vkId}`
        : `footer-${SHARE_BLOCK_CONSTS.vkId}`
    const mailId =
      props.url || props.title
        ? `article-${SHARE_BLOCK_CONSTS.mailId}`
        : `footer-${SHARE_BLOCK_CONSTS.mailId}`

    const shareBlockWrapperStyle = {
      width: '100%'
    }

    const shareBlockLinkStyle = {
      margin: '30px 30px 30px 0px'
    }

    const shareBlockStyle = {
      columnGap: columnGap,
      rowGap: rowGap,
      width: '100%'
    }

    const shareBlockTextStyle = {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '17px',
      verticalAlign: 'middle',
      marginBottom: '8px'
    }

    return (
      <div style={shareBlockWrapperStyle}>
        {showShareMsg === true && (
          <div style={shareBlockTextStyle}>
            <span>{SHARE_BLOCK_CONSTS.shareMsg}:&nbsp;</span>
            {/* <IconButton size='small' onClick={printPage}>
              <PrinterIcon></PrinterIcon>
            </IconButton> */}
          </div>
        )}
        <div style={shareBlockStyle}>
          <a
            href={telegramLink}
            target='_blank'
            rel='noreferrer'
            id={telegramId}
            style={shareBlockLinkStyle}
          >
            <AMPFooterTelegram
              isHeader={false}
              width={iconWidth}
              height={iconHeight}
            />
          </a>
          <a
            href={vkLink}
            target='_blank'
            rel='noreferrer'
            id={vkId}
            style={shareBlockLinkStyle}
          >
            <AMPFooterVK
              isHeader={false}
              width={iconWidth}
              height={iconHeight}
            />
          </a>
          {!props.title && (
            <a
              href={SHARE_BLOCK_CONSTS.dzenLinkUrl}
              target='_blank'
              rel='noreferrer'
              style={shareBlockLinkStyle}
            >
              <AMPFooterDzen
                isHeader={false}
                width={iconWidth}
                height={iconHeight}
              />
            </a>
          )}
          <a
            href={mailLink}
            target='_blank'
            rel='noreferrer'
            id={mailId}
            style={shareBlockLinkStyle}
          >
            <FooterMailingList
              isHeader={false}
              width={iconWidth}
              height={iconHeight}
            />
          </a>
        </div>
      </div>
    )
  })
)

export default AmpShareBlock
