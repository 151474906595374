import {makeObservable, observable, action} from 'mobx'

export interface AmpPublication {
  date_add: string
  title: string
  announce_yandex: string
  news_text: string
  author: string
  announce_pic: string
  news_section: number
  is_active: string
  sub_sent: string
  discuss: string
  title_yandex: string
  send_to_yandex: number
  send_to_mail_pulse: number
  send_to_yandex_dzen: number
  send_to_yandex_turbo: number
  send_to_facebook_instante_publications: number
  important: string
  hide_from_news_list: number
  video: string
  inn: string
  ver: number
  seo_title: string
  seo_description: string
  seo_keywords: string
  publisher: string
  photo: string
  is_finished: number
  uid: string
  date_create: string
  is_verified: number
  editor_uid: string
  last_edited_by: string
  disallow_auto_publication: number
  img_lg: string
  img_m: string
  main_announce_text: string
  main_announce_img: string
  banner_announce_text: string
  banner_announce_img: string
  img_lg_description: string
  img_lg_author: string
  is_top: number
  img_lg_incut: number
  og_title: string
  og_description: string
  is_online_translation: number
  og_image: string
  reason: string
  announce: string
  main_announce_title: string
  version: number
  for_analyst: number
  official_comment_title: string
  official_comment_author: string
  official_comment_links: string
  is_digitalization: number
  digitalization_theme: string
  news_id: string
  redirect_url: string
}

class AmpPublicationStore {
  // Объявляем наблюдаемые свойства
  @observable data: AmpPublication = {
    date_add: '',
    title: '',
    announce_yandex: '',
    news_text: '',
    author: '',
    announce_pic: '',
    news_section: 0,
    is_active: '',
    sub_sent: '',
    discuss: '',
    title_yandex: '',
    send_to_yandex: 0,
    send_to_mail_pulse: 0,
    send_to_yandex_dzen: 0,
    send_to_yandex_turbo: 0,
    send_to_facebook_instante_publications: 0,
    important: '',
    hide_from_news_list: 0,
    video: '',
    inn: '',
    ver: 0,
    seo_title: '',
    seo_description: '',
    seo_keywords: '',
    publisher: '',
    photo: '',
    is_finished: 0,
    uid: '',
    date_create: '',
    is_verified: 0,
    editor_uid: '',
    last_edited_by: '',
    disallow_auto_publication: 0,
    img_lg: '',
    img_m: '',
    main_announce_text: '',
    main_announce_img: '',
    banner_announce_text: '',
    banner_announce_img: '',
    img_lg_description: '',
    img_lg_author: '',
    is_top: 0,
    img_lg_incut: 0,
    og_title: '',
    og_description: '',
    is_online_translation: 0,
    og_image: '',
    reason: '',
    announce: '',
    main_announce_title: '',
    version: 0,
    for_analyst: 0,
    official_comment_title: '',
    official_comment_author: '',
    official_comment_links: '',
    is_digitalization: 0,
    digitalization_theme: '',
    news_id: '',
    redirect_url: ''
  }

  constructor() {
    makeObservable(this)
  }

  // Определяем экшн для отправки запроса
  @action async fetchPublication(newsId: number): Promise<void> {
    try {
      const response = await fetch(`/news/${newsId}`)
      const news = await response.json()
      this.data = news
    } catch (error) {
      console.error(error)
    }
  }
}

export default AmpPublicationStore
