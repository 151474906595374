import React, {FC} from 'react'
import {AmpFooterIconProps} from '../ampFooterIconProps'

const AMPFooterVK: FC<AmpFooterIconProps> = ({isHeader, width, height}) => {
  return !isHeader ? (
    <svg
      width={width ? width : '40'}
      height={height ? height : '40'}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='20' fill='#0077FF' />
      <g clipPath='url(#clip0_7666_186435)'>
        <path
          d='M20.9922 26.6668C13.3997 26.6668 9.06911 21.6618 8.88867 13.3335H12.6919C12.8168 19.4463 15.6205 22.0355 17.8413 22.5694V13.3335H21.4226V18.6054C23.6156 18.3785 25.9195 15.9761 26.6968 13.3335H30.2779C29.6811 16.5901 27.1827 18.9925 25.406 19.9801C27.1827 20.7809 30.0282 22.8764 31.1109 26.6668H27.1688C26.3221 24.131 24.2125 22.169 21.4226 21.9021V26.6668H20.9922Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_7666_186435'>
          <rect
            width='22.2222'
            height='22.2222'
            fill='white'
            transform='translate(8.88867 8.88867)'
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='20' height='20' rx='10' fill='#0077FF' />
      <g clipPath='url(#clip0_7666_186527)'>
        <path
          d='M10.4971 13.3333C6.70081 13.3333 4.53553 10.8308 4.44531 6.66667H6.3469C6.40936 9.72306 7.81123 11.0177 8.92165 11.2846V6.66667H10.7123V9.30264C11.8088 9.18919 12.9607 7.98799 13.3494 6.66667H15.1399C14.8415 8.29497 13.5923 9.49617 12.704 9.99C13.5923 10.3904 15.0151 11.4381 15.5564 13.3333H13.5854C13.162 12.0654 12.1072 11.0844 10.7123 10.951V13.3333H10.4971Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_7666_186527'>
          <rect
            width='11.1111'
            height='11.1111'
            fill='white'
            transform='translate(4.44531 4.44427)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AMPFooterVK
