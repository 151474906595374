import React, {FC, useEffect, useState} from 'react'
import {SvgIconProps} from '@mui/material/SvgIcon'
import colors from '../../../styles/colors.module.scss'

const ArrowUpIcon: FC<any> = (props: SvgIconProps) => {
  const fillColor = props?.color || colors?.colorDarkBlue
  const [width, setWidth] = useState(28)
  const [height, setHeight] = useState(28)

  useEffect(() => {
    setWidth(+(props?.width || 0) || 28)
    setHeight(+(props?.height || 0) || 28)
  }, [props])

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 26.2758V1.72392M14 1.72392L26.276 13.9998M14 1.72392L1.72397 13.9998'
        stroke={fillColor}
        strokeWidth='2.60413'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ArrowUpIcon
