import React, {FC} from 'react'
import {AmpFooterIconProps} from '../ampFooterIconProps'

const AMPFooterDzen: FC<AmpFooterIconProps> = ({isHeader, width, height}) => {
  return !isHeader ? (
    <svg
      width={width ? width : '40'}
      height={height ? height : '40'}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7666_186437)'>
        <path
          d='M0 20C0 8.95429 8.95429 0 20 0C31.0457 0 40 8.95429 40 20C40 31.0457 31.0457 40 20 40C8.95429 40 0 31.0457 0 20Z'
          fill='#222222'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.5953 0C19.5008 8.22814 18.9627 12.833 15.8979 15.8979C12.833 18.9627 8.22814 19.5008 0 19.5953V20.7894C8.22814 20.8838 12.833 21.4219 15.8979 24.4867C18.9146 27.5035 19.4833 32.0124 19.5905 40H20.7941C20.9013 32.0124 21.4699 27.5035 24.4867 24.4867C27.5035 21.4699 32.0124 20.9013 40 20.7941V19.5904C32.0124 19.4833 27.5035 18.9146 24.4867 15.8979C21.4219 12.833 20.8838 8.22814 20.7894 0H19.5953Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_7666_186437'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7666_186535)'>
        <path
          d='M0 10C0 4.47714 4.47714 0 10 0C15.5229 0 20 4.47714 20 10C20 15.5229 15.5229 20 10 20C4.47714 20 0 15.5229 0 10Z'
          fill='black'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M9.79764 0C9.75039 4.11407 9.48136 6.4165 7.94893 7.94893C6.4165 9.48136 4.11407 9.75039 0 9.79764V10.3947C4.11407 10.4419 6.4165 10.7109 7.94893 12.2434C9.45732 13.7517 9.74164 16.0062 9.79525 20H10.3971C10.4506 16.0062 10.735 13.7517 12.2434 12.2434C13.7517 10.735 16.0062 10.4506 20 10.3971V9.79521C16.0062 9.74164 13.7517 9.45732 12.2434 7.94893C10.7109 6.4165 10.4419 4.11407 10.3947 0H9.79764Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_7666_186535'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AMPFooterDzen
