import type {AppProps} from 'next/app'
import Head from 'next/head'
import {ApolloProvider} from '@apollo/client'
import {useApollo} from '../apollo/apollo'

import Header from '../components/header/header'
import '../styles/normalize.scss'
import '../styles/global.scss'
// import AdLoader from '../components/ui/blocks/advertisement/AdLoader/AdLoader'
import {Provider} from 'mobx-react'
import 'swiper/css/bundle'
import {DeviceSettingsStore} from '../stores/deviceSettingsStore'
import AmpPublicationStore from '../stores/ampPublicationStore'
import Footer from '../components/footer/footer'
import reportWebVitals from '../utils/reportWebVitals'
import {openSans} from '../../fonts'
import {useRef, useState, useCallback} from 'react'
import ScrollToTopButton from '../components/ui/blocks/scrollToTopButton/scrollToTopButton'
import {useAmp} from 'next/amp'
import AmpFooter from '../components/amp/ampFooter/ampFooter'
import InfiniteScroll from 'react-infinite-scroll-component'
import {LinearProgress} from '@mui/material'

type AppType = {} & AppProps

const App = ({Component, pageProps}: AppType): any => {
  const apolloClient = useApollo(pageProps)

  // const {isMobile} = useDeviceDetect()

  const deviceSettingsStore = DeviceSettingsStore
  // const deviceSettingsStore = new DeviceSettingsStore()

  const stores = {
    // rootStore: new rootStore(),
    deviceSettingsStore,
    AmpPublicationStore: AmpPublicationStore
  }

  reportWebVitals()

  const ref = useRef<HTMLDivElement>(null)

  const [scrollEventPosition, setScrollEventPosition] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [hasMore, setHasMore] = useState(false)

  const handleScroll = useCallback(() => {
    setScrollPosition(
      ref?.current && ref.current['scrollTop'] ? ref.current['scrollTop'] : 1
    )
  }, [setScrollPosition])

  const nextScroll = useCallback(() => {
    setScrollEventPosition(
      ref?.current && ref.current['scrollTop'] ? ref.current['scrollTop'] : 1
    )
  }, [setScrollEventPosition])

  const isAmp = useAmp()

  // const schema = {
  //   '@context': 'https://schema.org',
  //   '@type': 'Organization',
  //   name: 'ura-help',
  //   url: process.env.REACT_APP_URA_HELP_URL
  // }

  const [dataLengthForScroll, setDataLengthForScroll] = useState(0)

  return (
    <div
      id='scrollableDiv'
      style={{
        height: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollBehavior: 'smooth'
      }}
      ref={ref}
      onScroll={handleScroll}
    >
      <InfiniteScroll
        dataLength={dataLengthForScroll}
        next={nextScroll}
        hasMore={hasMore || false}
        scrollableTarget='scrollableDiv'
        loader={<LinearProgress color='primary' />}
      >
        <ApolloProvider client={apolloClient}>
          <Provider {...stores}>
            <Head>
              <meta
                name='zen-verification'
                content='nf3U09JMm1CIW2G0USNrhq9mvteNm7PssgT0mbFZ8Je4PuWzI0qtU6xvdnao4sZr'
              />
              <script>window.yaContextCb = window.yaContextCb || []</script>
              <script
                src='https://yandex.ru/ads/system/context.js'
                async
              ></script>
              <link rel='shortcut icon' href='/img/svg/favicon.svg' />
              {/* <script
                type='application/ld+json'
                dangerouslySetInnerHTML={{__html: JSON.stringify(schema)}}
              /> */}
            </Head>
            {/* <script
                type='text/javascript'
                src='https://vk.com/js/api/share.js?93'
                charSet='windows-1251'
            ></script> */}
            {/* <AdLoader></AdLoader> */}
            {/* {!deviceSettingsStore.isMobile && (
            <AdvertisementUpperBlock></AdvertisementUpperBlock>
          )}
          {deviceSettingsStore.isMobile && (
            <AdvertisementMobileUpperBlock></AdvertisementMobileUpperBlock>
          )} */}
            <style jsx global>{`
              html {
                font-family: ${openSans.style.fontFamily};
              }
            `}</style>
            <div>
              <Header />
              {/* <AMPHeader /> */}
              <Component
                {...pageProps}
                scrollEventPosition={scrollEventPosition}
                scrollPosition={scrollPosition}
                setDataLengthForScroll={setDataLengthForScroll}
                setHasMore={setHasMore}
              />
              {isAmp ? <AmpFooter /> : <Footer />}
              <ScrollToTopButton
                refContainer={ref}
                pos={scrollPosition}
                setPos={setScrollPosition}
              />
            </div>
          </Provider>
        </ApolloProvider>
      </InfiniteScroll>
    </div>
  )
}

export default App
