import React, {FC} from 'react'

type AMPFooterTelegramProps = {
  isHeader: boolean
  width?: number
  height?: number
}

const AMPFooterTelegram: FC<AMPFooterTelegramProps> = ({
  isHeader,
  width,
  height
}) => {
  return !isHeader ? (
    <svg
      width={width ? width : '36'}
      height={height ? height : '36'}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7674_119)'>
        <path
          d='M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z'
          fill='#2AABEE'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.05368 19.7889C14.8841 17.2487 18.7719 15.574 20.7172 14.7649C26.2714 12.4547 27.4256 12.0534 28.1778 12.0402C28.3433 12.0373 28.7132 12.0783 28.9528 12.2727C29.1551 12.4369 29.2108 12.6587 29.2374 12.8143C29.2641 12.97 29.2973 13.3246 29.2709 13.6017C28.9699 16.7641 27.6675 24.4386 27.005 27.9807C26.7246 29.4794 26.1726 29.9819 25.6382 30.0311C24.4767 30.138 23.5948 29.2636 22.4699 28.5262C20.7096 27.3723 19.7152 26.654 18.0065 25.528C16.0319 24.2268 17.312 23.5116 18.4373 22.3428C18.7318 22.0369 23.8491 17.3823 23.9482 16.9601C23.9606 16.9072 23.9721 16.7104 23.8551 16.6065C23.7382 16.5025 23.5656 16.5381 23.441 16.5663C23.2645 16.6064 20.4525 18.4651 15.0049 22.1423C14.2067 22.6904 13.4838 22.9574 12.836 22.9434C12.1219 22.928 10.7483 22.5397 9.72709 22.2077C8.47459 21.8006 7.47913 21.5853 7.56581 20.8939C7.61096 20.5337 8.10692 20.1654 9.05368 19.7889Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_7674_119'
          x1='20'
          y1='0'
          x2='20'
          y2='39.7033'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2AABEE' />
          <stop offset='1' stopColor='#229ED9' />
        </linearGradient>
        <clipPath id='clip0_7674_119'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7666_186523)'>
        <path
          d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
          fill='url(#paint0_linear_7666_186523)'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M4.52587 9.89445C7.44107 8.62434 9.38499 7.78701 10.3576 7.38245C13.1347 6.22735 13.7118 6.0267 14.0879 6.02007C14.1706 6.01862 14.3556 6.03912 14.4754 6.13634C14.5766 6.21843 14.6044 6.32932 14.6177 6.40715C14.6311 6.48498 14.6477 6.66228 14.6345 6.80082C14.484 8.38205 13.8328 12.2193 13.5015 13.9903C13.3613 14.7397 13.0853 14.991 12.8181 15.0155C12.2374 15.069 11.7964 14.6318 11.234 14.2631C10.3538 13.6861 9.85662 13.327 9.0023 12.764C8.01499 12.1134 8.65502 11.7558 9.21769 11.1714C9.36494 11.0184 11.9236 8.69113 11.9731 8.48001C11.9793 8.45361 11.9851 8.35518 11.9266 8.30321C11.8681 8.25124 11.7818 8.26901 11.7195 8.28315C11.6313 8.30318 10.2253 9.23251 7.50149 11.0711C7.10239 11.3452 6.74091 11.4787 6.41703 11.4717C6.05998 11.464 5.37315 11.2698 4.86257 11.1039C4.23632 10.9003 3.73859 10.7927 3.78193 10.4469C3.8045 10.2669 4.05248 10.0827 4.52587 9.89445Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_7666_186523'
          x1='10'
          y1='0'
          x2='10'
          y2='19.8517'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#2AABEE' />
          <stop offset='1' stop-color='#229ED9' />
        </linearGradient>
        <clipPath id='clip0_7666_186523'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AMPFooterTelegram
