import {useMemo} from 'react'
import {
  ApolloClient,
  ApolloLink,
  concat,
  defaultDataIdFromObject,
  createHttpLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import {relayStylePagination} from '@apollo/client/utilities'
import {onError} from '@apollo/client/link/error'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined
// export const getToken = async () => {
//   const requestOptions = {
//     // skipSSLValidation: true,
//     // rejectUnauthorized: false,
//     method: 'POST',
//     headers: {'Content-Type': 'application/x-www-form-urlencoded'},
//     body: new URLSearchParams({
//       grant_type: 'password',
//       client_id: 'backend',
//       username: 'a.safronov',
//       password: 'test'
//     }).toString()
//   }
//   const data = await fetch(
//     `https://stn03.uranews.xyz/auth/realms/uranews/protocol/openid-connect/token`,
//     requestOptions
//   )
//   return data.json()
// }

let accessToken

const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI
  })

  const logoutLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      for (const graphQLError of graphQLErrors) {
        if (graphQLError.extensions?.code === 'UNAUTHENTICATED') {
          // getToken()
          return
        }
      }
      console.error(graphQLErrors)
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
    }
  })

  return new ApolloClient({
    // link: authLink.concat(httpLink),
    link: httpLink,
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache({
      // typePolicies is not required to use Apollo with Next.js - only for doing pagination.
      typePolicies: {
        Query: {
          fields: {
            posts: relayStylePagination(),
            publications: relayStylePagination(),
            comments: relayStylePagination()
          }
        }
      }
    }),
    connectToDevTools: true
  })
}

export const initializeApollo = (
  initialState: NormalizedCacheObject | null = null
): ApolloClient<NormalizedCacheObject> => {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s)))
      ]
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any
): any => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export const useApollo = (
  pageProps: any
): ApolloClient<NormalizedCacheObject> => {
  const state = pageProps ? pageProps[APOLLO_STATE_PROP_NAME] : {}
  return useMemo(() => initializeApollo(state), [state])
}
