export const COLORS_CONSTS = {
  colorAdvertisement: '#d9d9d9',
  colorBackground: '#f1f6ff',
  colorBlack: '#222222',
  colorDarkBlue: '#1f299c',
  colorDarkGrey: '#6e747a',
  colorError: '#c31e28',
  colorGrey100: '#4e4e4e',
  colorGrey50: '#707070',
  colorGrey30: '#b7b7b7',
  colorGrey20: '#c4c4c4',
  colorGrey: '#f4f4f4',
  colorLightBlue: '#0372ff',
  colorLightGrey: '#f4f4f4',
  colorMain: '#1964e7',
  colorMainNight: '#6da2ff',
  colorNight: '#141414',
  colorWhite: '#ffffff'
}
