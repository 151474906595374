import {inject, observer} from 'mobx-react'
import {FC, useEffect, useState} from 'react'
import {DeviceSettingsStore} from '../../../../stores/deviceSettingsStore'
import styles from './shareBlock.module.scss'
import AMPFooterDzen from '../../icons/ampIcons/footerIcons/footerDzen/footerDzen'
import AMPFooterTelegram from '../../icons/ampIcons/footerIcons/footerTelegram/footerTelegram'
import AMPFooterVK from '../../icons/ampIcons/footerIcons/footerVK/footerVK'
import {SHARE_BLOCK_CONSTS} from './shareBlock.consts'
import FooterMailingList from '../../icons/ampIcons/footerIcons/footerMailingList/footerMailingList'
import {Tooltip} from '@mui/material'

type ShareBlockProps = {
  showShareMsg: boolean
  iconHeight: number
  iconWidth: number
  deviceSettingsStore: DeviceSettingsStore
  columnGap: string
  rowGap: string
  url?: string
  id?: string
  title?: string
}

const ShareBlock: FC<any> = inject('deviceSettingsStore')(
  observer((props: ShareBlockProps) => {
    const [darkMode, setDarkMode] = useState<boolean>(
      props?.deviceSettingsStore?.darkMode || false
    )

    useEffect(() => {
      setDarkMode(props?.deviceSettingsStore?.darkMode || false)
    }, [props?.deviceSettingsStore?.darkMode])

    const [showShareMsg, setShowShareMsg] = useState(false)

    // const printPage = useCallback(() => {
    //   window.print()
    // }, [])

    const [iconHeight, setIconHeight] = useState(24)
    const [iconWidth, setIconWidth] = useState(24)
    const [columnGap, setColumnGap] = useState('34px')
    const [rowGap, setRowGap] = useState('20px')
    const [telegramLink, setTelegramLink] = useState('')
    const [vkLink, setVkLink] = useState('')
    const [mailLink, setMailLink] = useState('')
    const [telegramId, setTelegramId] = useState('')
    const [vkId, setVkId] = useState('')
    const [mailId, setMailId] = useState('')
    const [isArticle, setIsArticle] = useState(false)

    useEffect(() => {
      setShowShareMsg(props.showShareMsg)
      setIconHeight(props.iconHeight)
      setIconWidth(props.iconWidth)
      setColumnGap(props.columnGap)
      setRowGap(props.rowGap)
      setTelegramLink(
        props.url || props.title
          ? `https://telegram.me/share/url?url=${props.url}`
          : SHARE_BLOCK_CONSTS.telegramLinkUrl
      )
      setVkLink(
        props.url || props.title
          ? `https://vk.com/share.php?url=${props.url}&text=${props.title}`
          : SHARE_BLOCK_CONSTS.vKLinkUrl
      )
      setMailLink(
        props.url || props.title
          ? `mailto:&body=${SHARE_BLOCK_CONSTS.mailPreMessage}${props.url}?subject=${props.title}`
          : `mailto:obzor@ura.ru`
      )
      setTelegramId(
        props.url || props.title
          ? `article-${SHARE_BLOCK_CONSTS.telegramId}-${props?.id}`
          : `footer-${SHARE_BLOCK_CONSTS.telegramId}`
      )
      setVkId(
        props.url || props.title
          ? `article-${SHARE_BLOCK_CONSTS.vkId}-${props?.id}`
          : `footer-${SHARE_BLOCK_CONSTS.vkId}`
      )
      setMailId(
        props.url || props.title
          ? `article-${SHARE_BLOCK_CONSTS.mailId}-${props?.id}`
          : `footer-${SHARE_BLOCK_CONSTS.mailId}`
      )
      setIsArticle(props.url || props.title ? true : false)
    }, [props])

    return (
      <div className={styles.shareBlockWrapper}>
        {showShareMsg === true && (
          <div
            className={
              darkMode ? styles.shareBlockTextNight : styles.shareBlockText
            }
          >
            <span>{SHARE_BLOCK_CONSTS.shareMsg}:&nbsp;</span>
            {/* <IconButton size='small' onClick={printPage}>
              <PrinterIcon></PrinterIcon>
            </IconButton> */}
          </div>
        )}
        <div
          className={styles.shareBlock}
          style={{
            columnGap: columnGap,
            rowGap: rowGap
          }}
        >
          <Tooltip
            title={
              `${isArticle ? SHARE_BLOCK_CONSTS.shareInMsg : ''}` +
              SHARE_BLOCK_CONSTS.telegram
            }
          >
            <a
              href={telegramLink}
              target='_blank'
              rel='noreferrer'
              id={telegramId}
            >
              <AMPFooterTelegram
                isHeader={false}
                width={iconWidth}
                height={iconHeight}
              />
            </a>
          </Tooltip>
          <Tooltip
            title={
              `${isArticle ? SHARE_BLOCK_CONSTS.shareInMsg : ''}` +
              SHARE_BLOCK_CONSTS.vkontakte
            }
          >
            <a href={vkLink} target='_blank' rel='noreferrer' id={vkId}>
              <AMPFooterVK
                isHeader={false}
                width={iconWidth}
                height={iconHeight}
              />
            </a>
          </Tooltip>
          {!props.title && (
            <Tooltip
              title={
                `${isArticle ? SHARE_BLOCK_CONSTS.shareInMsg : ''}` +
                SHARE_BLOCK_CONSTS.dzen
              }
            >
              <a
                href={SHARE_BLOCK_CONSTS.dzenLinkUrl}
                target='_blank'
                rel='noreferrer'
              >
                <AMPFooterDzen
                  isHeader={false}
                  width={iconWidth}
                  height={iconHeight}
                />
              </a>
            </Tooltip>
          )}
          {isArticle && (
            <Tooltip title={SHARE_BLOCK_CONSTS.mailLinkMsg}>
              <a href={mailLink} target='_blank' id={mailId}>
                <FooterMailingList
                  isHeader={false}
                  width={iconWidth}
                  height={iconHeight}
                />
              </a>
            </Tooltip>
          )}
        </div>
      </div>
    )
  })
)

export default ShareBlock
