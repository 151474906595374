import Link from 'next/link'
import styles from './header.module.scss'

import {FC} from 'react'
import Logo from '../../assets/icons/svg/logo.svg'
// import {DeviceSettingsStore} from '../../stores/deviceSettingsStore'
import {useAmp} from 'next/amp'

// type HeaderProps = {
//   deviceSettingsStore?: DeviceSettingsStore
// }

export const config = {amp: 'hybrid'}

const Header: FC<any> = () => {
  // FC<HeaderProps> = inject('deviceSettingsStore')(
  // observer((props): JSX.Element => {

  // const [scrollRight, setScrollRight] = useState(false)
  // const [scrollLeft, setScrollLeft] = useState(true)
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  // const scrollContainer = useRef<any>(null)

  // const router = useRouter()
  // const query = router.query

  // const windowsSize = useWindowResize()

  // const [isDarkMode, setIsDarkMode] = useState(
  //   props?.deviceSettingsStore?.darkMode || false
  // )
  const isAmp = useAmp()

  // useEffect(() => {
  //   setIsDarkMode(props?.deviceSettingsStore?.darkMode || false)
  // }, [props?.deviceSettingsStore?.darkMode])

  // useEffect(() => {
  //   if (scrollContainer?.current?.offsetWidth < 840) {
  //     setScrollRight(true)
  //   } else {
  //     setScrollRight(false)
  //   }
  //   if (scrollContainer?.current?.scrollLeft === 0) {
  //     setScrollLeft(false)
  //   }
  // }, [windowsSize.width])

  const headerStyle = {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row' as const,
    alignItems: 'center',
    backgroundColor: ' #FAFAFA',
    color: '#1964E7',
    height: '80px',
    padding: '0 20px',
    borderTop: '1px solid #E0E5ED'
  }

  return (
    <div>
      {/* isSideBar:{isSidebarOpen.toString()} */}
      {isAmp ? (
        <header style={headerStyle}>
          <a href={'/'}>
            <Logo />
          </a>
        </header>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div>
              <Link href={'/'} className={styles.logoWrapper}>
                <Logo />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Header
