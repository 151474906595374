import {FC, useCallback, useEffect, useState} from 'react'
import ArrowUpIcon from '../../icons/arrowUp'
import styles from './scrollToTopButton.module.scss'
import useDeviceDetect from '../../../../utils/useDeviceDetect'

export type ScrollToTopButtonProps = {
  refContainer: any
  pos: number
  setPos: (val: number) => void
}

const ScrollToTopButton: FC<ScrollToTopButtonProps> = props => {
  const [curPos, setCurPos] = useState(0)
  const {isMobile} = useDeviceDetect()

  const [iconHeight, setIconHeight] = useState(64)
  const [iconWidth, setIconWidth] = useState(64)

  useEffect(() => {
    setIconHeight(isMobile ? 19 : 28)
    setIconWidth(isMobile ? 19 : 28)
  }, [isMobile])

  useEffect(() => {
    setCurPos(props.pos)
  }, [props])

  const handleTop = useCallback(() => {
    if (props.refContainer?.current?.scrollTop) {
      props.refContainer.current.scrollTop = 0
      props.setPos(0)
    }
  }, [props])

  return (
    <button
      className={styles.scrollToTopBtn}
      style={{
        display: curPos > 50 ? 'block' : 'none'
      }}
      onClick={handleTop}
    >
      <ArrowUpIcon width={iconWidth} height={iconHeight} />
    </button>
  )
}

export default ScrollToTopButton
