import {action, makeAutoObservable, observable} from 'mobx'

// configure({enforceActions: 'always'})

export class DeviceSettingsStore {
  @observable darkMode = false
  @observable isMobile = false

  constructor() {
    makeAutoObservable(this)

    if (typeof window !== 'undefined') {
      const isDarkMode = localStorage?.getItem('isDarkMode')

      if (isDarkMode) {
        this.setDarkMode(isDarkMode === 'true' ? true : false)
      } else {
        if (
          window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
          this.setDarkMode(true)
        } else {
          this.setDarkMode(false)
        }
      }

      localStorage.setItem('isDarkMode', this.darkMode.toString())

      const media = window.matchMedia(`(max-width: 768px)`)
      this.isMobile = media.matches

      // const userAgent =
      //   typeof window.navigator === undefined ? '' : navigator?.userAgent
      // const mobile = Boolean(
      //     userAgent.match(
      //         /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      //     )
      // )
      // console.log('window.navigator', window.navigator)
      // console.log('window.matchMedia', window)
      // console.log(userAgent)
      // setMobile(mobile)
    }
  }

  @action setDarkMode = (value: boolean) => {
    this.darkMode = value
  }

  @action getDarkMode = (): boolean => {
    return this.darkMode
  }

  @action getIsMobile = (): boolean => {
    return this.isMobile
  }
}

// const deviceSettingsStore = new DeviceSettingsStore()

// autorun(() => {
//     console.log(localStorage?.getItem('isDarkMode'))
//     deviceSettingsStore.setDarkMode(
//         localStorage?.getItem('isDarkMode') === 'true' ? true : false
//     )
// })
// export const DarkModeStoreContext = createContext(new DeviceSettingsStore())
