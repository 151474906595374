import React, {FC, useEffect, useState} from 'react'
import {FOOTER_CONSTS} from './footer.consts'
import styles from './footer.module.scss'
import Logo from '../../assets/icons/svg/logo.svg'
import useDeviceDetect from '../../utils/useDeviceDetect'
import ShareBlock from '../ui/blocks/shareBlock/shareBlock'
import Link from 'next/link'

const Footer: FC<any> = () => {
  const {isMobile} = useDeviceDetect()

  const [iconHeight, setIconHeight] = useState(24)
  const [iconWidth, setIconWidth] = useState(24)

  useEffect(() => {
    setIconHeight(isMobile ? 36 : 24)
    setIconWidth(isMobile ? 36 : 24)
  }, [isMobile])

  return (
    <div className={styles.footerWrapperContainer}>
      <div className={styles.footerContainer}>
        <div className={styles.logosBlock}>
          <div className={styles.logosBlockRow}>
            <div>
              <Logo />
              <div className={styles.ageRestrictionLogo}>
                {FOOTER_CONSTS.ageRestrectionBanner}
              </div>
            </div>
            <div className={styles.footerRightColumn}>
              <div className={styles.legalInfoBlock}>
                <div className={styles.legalInfoBlockText}>
                  <div>{FOOTER_CONSTS.siteNameInfo}</div>
                  <div>
                    <span>{FOOTER_CONSTS.domainName}</span>
                    <Link href='/'>ura-help.ru</Link>
                  </div>
                  <div>
                    {FOOTER_CONSTS.emailMsg}
                    <Link href={`mailto:${FOOTER_CONSTS.email}`}>
                      {FOOTER_CONSTS.email}
                    </Link>
                  </div>
                  {/* <div>
                {FOOTER_CONSTS.mainRedactorHeader}{' '}
                {FOOTER_CONSTS.mainRedactorName}
              </div>
              <div itemProp='telephone'>
                {FOOTER_CONSTS.legalInfoBanner3Phone}
              </div>
              <div>
                {FOOTER_CONSTS.legalInfoBanner3Mail}{' '}
                {FOOTER_CONSTS.redactionMail}
              </div> */}
                </div>
              </div>
              <div className={styles.subscribeBlock}>
                <div className={styles.iconsBlock}>
                  <ShareBlock
                    columnGap={'34px'}
                    rowGap={'20px'}
                    showShareMsg={false}
                    iconHeight={iconHeight}
                    iconWidth={iconWidth}
                  ></ShareBlock>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.agreementsBlock}>
        <div>
          <a
            className={styles.agreementsBlockLink}
            href='/user-agreements'
            target='_blank'
            rel='noreferrer'
          >
            {FOOTER_CONSTS.termsOfUseLink}
          </a>
        </div>
        <div className={styles.agreementsBlockDevider}></div>
        <div>
          <a
            className={styles.agreementsBlockLink}
            href='/confidential-policy'
            target='_blank'
            rel='noreferrer'
          >
            {FOOTER_CONSTS.privacyPolicyLink}
          </a>
        </div>
      </div> */}
    </div>
  )
}
export default Footer
